<template>
	<div class="reading_container">
		<div class="reading_info_contents">
			<div class="title">[store] 스토어</div>
			<div class="Board">
				<table class="Board_type1">
					<colgroup><col width="0">
						<col width="200">
						<col width="350">
						<col width="*">
					</colgroup>
					<tbody>
						<tr><td></td>
							<td class="level2">사용자정보</td>
							<td class="source">$store.state.userInfo</td>
							<td class="sample">{{$store.state.userInfo}}</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
</template>

<script>
//import cscript from "./CScript.vue";
export default {
	name: 'storeGuide',
//	components:{ cscript },
	data() {
		return {};
	},

};
</script>