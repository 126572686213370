<template>
	<div class="reading_container">
		<div class="reading_info_contents">
			<div class="title">[route] 페이지 정보</div>
			<div class="Board">
				<table class="Board_type1">
					<colgroup><col width="0">
						<col width="200">
						<col width="350">
						<col width="*">
					</colgroup>
					<tbody>
						<tr><td></td>
							<td class="level2">페이지 ID</td>
							<td class="source">$route.name<br/>$router.history.current.name</td>
							<td class="sample">route 객체 : {{$route.name}}<br/>router 히스토리 : {{$router.history.current.name}}</td>
						</tr>
						<tr><td></td>
							<td class="level2">페이지 path</td>
							<td class="source">$route.path<br/>$router.history.current.path</td>
							<td class="sample">route 객체 : {{$route.path}}<br/>router 히스토리 : {{$router.history.current.path}}</td>
						</tr>
						<tr><td></td>
							<td class="level2">페이지 full path</td>
							<td class="source">$route.fullPath<br/>$router.history.current.fullPath</td>
							<td class="sample">route 객체 : {{$route.fullPath}}<br/>router 히스토리 : {{$router.history.current.fullPath}}</td>
						</tr>
						<tr><td></td>
							<td class="level2">파라미터</td>
							<td class="source">$route.params<br/>$router.history.current.params</td>
							<td class="sample">route 객체 : {{$route.params}}<br/>router 히스토리 : {{$router.history.current.params}}</td>
						</tr>
						<tr><td></td>
							<td class="level2">쿼리스트링</td>
							<td class="source">$route.query<br/>$router.history.current.query</td>
							<td class="sample">route 객체 : {{$route.query}}<br/>router 히스토리 : {{$router.history.current.query}}</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
</template>

<script>
//import cscript from "./CScript.vue";
export default {
	name: 'routeGuide',
//	components:{ cscript },
	data() {
		return {};
	},

};
</script>