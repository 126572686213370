<template>
  	<div class="main-contents">
		<div class="mem_container guide">
			<div class="mem_info_contents">
				<tab-comp class="tab-type2" type="view" :list="viewList" nameKey="name" viewKey="view" onClass="on" @open="openTab" @close="closeTab"/>
			</div>
	  	</div>
	</div>
</template>

<script>
import routeGuide from "./sub/routeGuide.vue";
import routerGuide from "./sub/routerGuide.vue";
import storeGuide from "./sub/storeGuide.vue";
import apiGuide from "./sub/apiGuide.vue";
import formatGuide from "./sub/formatGuide.vue";
import inputGuide from "./sub/inputGuide.vue";
import fileGuide from "./sub/fileGuide.vue";
import popupGuide from "./sub/popupGuide.vue";
import logGuide from "./sub/logGuide.vue";
import compGuide from "./sub/compGuide.vue";
import tabComp from "@/components/TabComp.vue";

export default {

    components: {
		tabComp
    },
	data() {
		return {
			viewList : [
				{ name: 'Route', view: routeGuide }, 
				{ name: 'Router', view: routerGuide }, 
				{ name: '저장소', view: storeGuide }, 
				{ name: 'API', view: apiGuide }, 
				{ name: '포맷팅', view: formatGuide }, 
				{ name: 'Input', view: inputGuide }, 
				{ name: 'File', view: fileGuide }, 
				{ name: 'Popup', view: popupGuide }, 
				{ name: '컴포넌트', view: compGuide }, 
				{ name: '로그', view: logGuide }
			]
		};
	},
	beforeCreate(){
		console.log('guide', '===== beforeCreate =====\n', '컴포넌트 초기화 전 단계로 가장 처음 실행.\n', 'data와 이벤트($on, $once, $off, $emit), 감시자($watch) 등이 설정되기 전\n');
	},
	created(){
		console.log('guide', '===== created =====\n', '컴포넌트 초기화 후 호출\n', 'data, computed, methods, watch 등 사용 가능.\n', '아직 DOM에 컴포넌트가 마운트 되지 않았기 때문에 $el은 사용할 수 없음.\n');
	},
	beforeMount(){
		console.log('guide', '===== beforeMount =====\n', '템플릿과 렌더 함수들이 컴파일된 후 첫 렌더링이 일어나기 직전에 실행.\n');
	},
	mounted(){
		console.log('guide', '===== mounted =====\n', '컴포넌트, 템플릿, 렌더링된 돔에 접근가능.\n', '하위 컴포넌트가 먼저 마운트 된 후 실행.\n', '모든 하위 컴포넌트가 마운트된 상태를 보장하지 않음.\n');
		this.guideLog('mounted')
	},
	beforeUpdate(){
		console.log('guide', '===== beforeUpdate =====\n', 'DOM이 재 렌더링 되기 직전에 실행.\n');
	},
	updated(){
		console.log('guide', '===== updated =====\n', 'DOM이 재 렌더링 된 후 실행.\n');
		//this.guideLog('updated')
	},
	beforeDestory(){
		console.log('guide', '===== beforeDestory =====\n', '컴포넌트가 제거 되기 직전에 실행.\n');
	},
	destoryed(){
		console.log('guide', '===== destoryed =====\n', '컴포넌트가 제거 된 후 실행.\n');
	},
	methods: {
		guideLog(step){
			console.log('guide', '========== sample ' + step + ' log ~~~~~~~~~~', '\n'
				, '- 현재페이지(this.$route)', this.$route, '\n'
				, '- Router(this.$router)', this.$router, '\n'
				, '- 히스토리(this.$router.history)', this.$router.history, '\n'
				, '- 레퍼런스 객체(this.refs : 태그에 ref 선언)', this.$refs, '\n'
				, '~~~~~~~~~~ sample ' + step + ' log ==========');
		},

		openTab(tabIdx, tabItem){
			console.log('guide', 'openTab', tabIdx, tabItem);
		},
		closeTab(tabIdx, tabItem){
			console.log('guide', 'closeTab', tabIdx, tabItem);
		},
	},
};
</script>
<style>
	.guide .tab-type2 ul.tab li { width:100px; margin-right: 2px; height: 30px; line-height: 30px; font-size:15px; padding-top: 0px;}
	.guide .level1 {}
	.guide .level2 {}
	.guide .source { word-break: break-word; }
	.guide .sample { }
	.guide .sample a { color: #3af; text-decoration: auto;}
	.guide .Board .Board_type1 tbody td { text-align:left; }
	.guide .error { display: block; }
	.guide b {font-weight: bold !important;}
</style>