<template>
	<div>
		<div class="tab-header" :class="headerClass">
			<slot name="headerTop"></slot>
			<ul class="tab">
				<li class="tab-name" v-for="idx in length" :key="idx" :class="idx == now ? onClass : ''" @click="open(idx)">
					<slot name="tabName" :index="idx" :item="getItem(idx)" :on="idx == now">{{getName(idx)}}</slot>
				</li>
			</ul>
			<slot name="headerBottom"></slot>
		</div>
		<slot v-if="now > 0" :item="getItem(now)" :index="now">
			<slot name="tabHeader"></slot>
			<component v-if="getType(now) == 'view'" :is="getView(now)"/>
			<img v-else-if="getType(now) == 'image'" :src="getView(now)"/>
			<div v-else-if="getType(now) == 'html'" v-html="getView(now)"></div>
			<div v-else>{{getView(now)}}</div>
			<slot name="tabBottom"></slot>
		</slot>
	</div>
</template>

<script>
/**
 * 탭 컴포넌트
 *      -> 화면명, 경로를 받아 탭으로 화면 조회
 * 
 * 사용법)
 *      props   : 
 *                  type : 컨텐츠의 타입
 * 					list : 컨텐츠 목록
 * 					size : 컨텐츠 수 (list 있을 경우 생략)
 * 					onClass : 선택 된 탭에 적용할 class
 * 					headerClass : 탭에 적용할 class
 * 					nameKey : list 가 object 일 경우 탭 이름을 찾을 key 값.
 * 					viewKey : list 가 object 일 경우 개별 View을 찾을 key 값.
 * 					typeKey : list 가 object 일 경우 개별 type을 찾을 key 값. 없을경우 type props 의 값을 사용한다.
 *      emit    :  ※ 탭 변경 시 '변경 전 탭'의 close 이벤트 후 '변경 후 탭'의 open 이벤트 발생.
 * 					open : 탭 열릴때 이벤트
 * 					close: 탭 종료 이벤트
 *
 * 예시)
 *      <tab-comp class="tab-type2" type="view" :list="viewList" nameKey="name" onClass="on"/>
 * 
 */
export default {
	name: 'SlideComp',
	props: {
		type : String,
		list : Array,
		size : Number,
		onClass  : String,
		headerClass : {},
		nameKey : { type:String, default:'name'},
		typeKey : { type:String, default:''},
		viewKey : { type:String, default:'conts'},
	},
	data(){
		return {
			tabs : [],
			now : 0,
		}
	},
	mounted() {
		if(this.list.length > 0){
			for(var i in this.list){
				this.add(this.list[i]);
			}
		} else if(this.size > 0){
			for(var n=0; n<this.size; n++){
				this.add(n);
			}
		}

		if(this.length > 0){
			this.open(1);
		}
	},
	methods: {
		open(idx){
			if(this.now > 0) this.$emit('close', this.now, this.getItem(this.now));
			this.now = idx;
			this.$emit('open', this.now, this.getItem(this.now));
		},
		add(item){
			this.tabs.push(item);
		},
		remove(idx){
			if(0 < idx && idx <= this.tabs.length)
				this.tabs.splice(idx-1, 1);
		},
		getItem(idx){
			if(0 < idx && idx <= this.tabs.length)
				return this.tabs[idx-1];
			else 
				return null;
		},
		getName(idx){
			var item = this.getItem(idx);
			if(typeof item == 'object' && item && item[this.nameKey]){
				return item[this.nameKey];
			}
			return item;
		},
		getView(idx){
			var item = this.getItem(idx);
			if(typeof item == 'object' && item && item[this.viewKey]){
				return item[this.viewKey];
			}
			return item;
		},
		getType(idx){
			var item = this.getItem(idx);
			if(this.typeKey && typeof item == 'object' && item && item[this.typeKey]){
				return item[this.typeKey];
			}
			return this.type;
		}
	},
	computed: {
		length(){
			return this.tabs.length;
		},
	}
}
</script>
<style scoped>
.tab-name { float:left; cursor: pointer; }
</style>