<template>
	<div class="reading_container" ref="api">
		<div class="reading_info_contents">
			<div class="title">[api] API 호출 (axios API)</div>
			<div class="Board">
				<table class="Board_type1">
					<colgroup><col width="0">
						<col width="200">
						<col width="350">
						<col width="*">
					</colgroup>
					<tbody>
						<tr><td></td>
							<td class="level2">http</td>
							<td class="source">$.http(url, param).then(res => {response = res.data}).catch(err => $.httpErrorCommon(err, '기본에러 메시지'))</td>
							<td class="sample">
								url : <input type="text" v-model="url"/><br/>
								param : <textarea type="text" v-model="param"></textarea><br/>
								<a href="javascript:void(0)" @click="$.http(url, JSON.parse(param)).then(res => {response = res.data}).catch(err => $.httpErrorCommon(err, '기본에러 메시지'))">조회</a> | 
								<a href="javascript:void(0)" @click="response = ''">결과삭제</a><br/>
								결과 : {{ response }}
							</td>
						</tr>
						<tr><td></td>
							<td class="level2">httpGet</td>
							<td class="source">$.httpGet(url, param).then(res => {response = res.data}).catch(err => $.httpErrorCommon(err, '기본에러 메시지'))</td>
							<td class="sample">
								url : <input type="text" v-model="url"/><br/>
								param : <textarea type="text" v-model="param"></textarea><br/>
								<a href="javascript:void(0)" @click="$.httpGet(url, JSON.parse(param)).then(res => {response = res.data}).catch(err => $.httpErrorCommon(err, '기본에러 메시지'))">조회</a> | 
								<a href="javascript:void(0)" @click="response = ''">결과삭제</a><br/>
								결과 : {{ response }}
							</td>
						</tr>
						<tr><td></td>
							<td class="level2">httpPost</td>
							<td class="source">$.httpPost(url, param).then(res => {response = res.data}).catch(err => $.httpErrorCommon(err, '기본에러 메시지'))</td>
							<td class="sample">
								url : <input type="text" v-model="url"/><br/>
								param : <textarea type="text" v-model="param"></textarea><br/>
								<a href="javascript:void(0)" @click="$.httpGet(url, JSON.parse(param)).then(res => {response = res.data}).catch(err => $.httpErrorCommon(err, '기본에러 메시지'))">조회</a> | 
								<a href="javascript:void(0)" @click="response = ''">결과삭제</a><br/>
								결과 : {{ response }}
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'apiGuide',
	data() {
		return {
			url : '/api/code/getCommonCode',
			param : '{}',
			response : ''
		};
	},
	methods: {

	},
};
</script>