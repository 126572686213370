<template>
  	<div class="reading_container">
		<div class="reading_info_contents">
			<div class="title">[file] 파일관리</div>
			<div class="Board">
				<table class="Board_type1">
					<colgroup><col width="0">
						<col width="200">
						<col width="350">
						<col width="*">
					</colgroup>
					<tbody>
						<tr><td></td>
							<td class="level2">파일선택</td>
							<td class="source"></td>
							<td class="sample">
								<file-select type="file" v-model="file" :fileName="fileName" disabled="Y"/>
							</td>
						</tr>
						<tr><td></td>
							<td class="level2">이미지 선택</td>
							<td class="source"></td>
							<td class="sample">
								<file-select type="image" v-model="image" :src="src" :defaultSrc="defaultSrc"/>
							</td>
						</tr>
						<tr><td></td>
							<td class="level2">파일업로드</td>
							<td class="source"></td>
							<td class="sample">
							</td>
						</tr>
						<tr><td></td>
							<td class="level2">파일다운로드</td>
							<td class="source">$.fileDownload(url, data, options, method))</td>
							<td class="sample">
							url : <input type="text" v-model="downloadUrl"/><br/>
							param : <textarea type="text" v-model="downloadParam"></textarea><br/>
							Method : <select v-model="downloadMethod"><option value="get">get</option><option value="post">post</option></select><br/>
							<a href="javascript:void(0)" @click="$.fileDownload(downloadUrl, downloadParam?JSON.parse(downloadParam):'', {}, downloadMethod)">파일다운로드</a>
							</td>
						</tr>
						<tr><td></td>
							<td class="level2">이미지 조회</td>
							<td class="source">
								&lt;ImageComp :src="src" :defaultSrc="defaultSrc"/&gt;
							</td>
							<td class="sample">
								src        : <input type="text" v-model="src"/><br/>
								defaultSrc : <input type="text" v-model="defaultSrc"/><br/>
								※ defaultSrc 에 등록 된 KEY 값을 넣으면 해당 이미지를 기본값으로 사용하며, 그 외의 값은 URL로 인식한다.<br/>
								<nbsp/>- PROFILE : '/images/profile_df01.png'<br/>
								<nbsp/>- COMPANY : '/images/profile_df02.png'<br/>
								<ImageComp :src="src" :defaultSrc="defaultSrc"/>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
</template>

<script>
import fileSelect from "@/components/FileSelect.vue";
export default {
	name: 'fileGuide',
	components: { fileSelect },
	data() {
		return {
			uploadUrl : '',
			file: null,
			fileName: '',
			files : [],
			downloadUrl : '/images/logo.png',
			downloadParam: '',
			downloadMethod: 'get',
			src : '/api/mem/prf/profile?tecMberSeq=10002',
			defaultSrc: 'PROFILE',
			image: null,
		};
	},
	methods: {
	},
};
</script>