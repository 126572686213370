<template>
	<div class="reading_container">
		<div class="reading_info_contents">
			<div class="title">[comp] 컴포넌트</div>
			<div class="Board">
				<table class="Board_type1">
					<colgroup><col width="0">
						<col width="300">
						<col width="*">
					</colgroup>
					<tbody>
						<tr><td></td>
							<td class="level2">페이징 처리</td>
							<td class="source">
								<pre>
기능 : 검색결과 테이블 하단의 페이징를 노출 페이지번호 클릭시 페이지 번호를 emit으로 전송

사용방법 : 
&lt;pagingComp :pageInfo="pageInfo" @page-click="goPage"/&gt;

* props 
        pageInfo : 서버의 PaginationUtils의 데이터
* emit
        page-click : 페이지번호 클릭시 클릭한 페이지번호 전송

* BBS001M01.vue 소스 참고 <span class="over" @click="component.pagingCompSample = !component.pagingCompSample"><b>샘플보기</b></span>
<div v-if="component.pagingCompSample">

&lt;pagingComp :pageInfo="pageInfo" @page-click="goPage"/&gt;

&lt;script&gt;
import pagingComp from '@/components/PagingComp.vue';

export default {
	components : {
		pagingComp
	},
	data () {
		return {
			pageInfo : {},
			pageIndex : 1,
		}
	},
	methods : {
		goPage(page) {
			this.pageIndex = page;
			this.getBbsList();
		},
	}
}
&lt;/script&gt;
</div>
									</pre>
								</td>
							</tr>	
							<tr><td></td>
								<td class="level2">사진 미리보기</td>
								<td class="source">
									<pre>
기능 : 파일선택 후 선택한 이미지를 미리보기로 보여주고 부모에게 파일정보를 emit 으로 전송													

사용방법 :
&lt;picPreviewComp @pic-file="setPicFile" /&gt;

* props 
        없음
* emit
        pic-file : 파일정보 전송

* quickmenu.vue 소스 참고 <span class="over" @click="component.pickCompSample = !component.pickCompSample"><b>샘플보기</b></span>
<div v-if="component.pickCompSample">
&lt;picPreviewComp @pic-file="setPicFile" /&gt;

&lt;script&gt;
import picPreviewComp from "@/components/PicPreviewComp.vue";

export default {
    components: {
        picPreviewComp,
    },
    data() {
        return {
            picFile : Object,           // 사진파일
        };
    },
    methods: {
        // 사진 미리보기의 파일정보
        setPicFile(picFile) {
		    this.picFile = picFile;
            //console.log('this.setPicFile', this.picFile);
        },
    },
};
&lt;/script&gt;
</div>										</pre>
										</td>
									</tr>	
									<tr><td></td>
										<td class="level2">화면내 바로가기</td>
										<td class="source">
											<pre>
기능 : 화면내에서 바로가기를 위한 메뉴를 노출하고 메뉴 클릭시 해당 위치로 이동
        부모 컴포넌트에는 바로가기로 이동할 부분에 ref 설정 필요

사용방법 :														
&lt;quickMenuComp :isOpenProps="false" :quickMenuInfo="quickMenuInfo" /&gt;

* props
		isOpenProps : 디폴트로 바로가기 메뉴를 열린 상태로 보여줄지 여부 (기본값은 false)
		quickMenuIfno : 바로가기 메뉴 정보 (메뉴명, ref명)

* emit
		없음

* quickmenu.vue 소스 참고 <span class="over" @click="component.quickCompSample = !component.quickCompSample"><b>샘플보기</b></span>
<div v-if="component.quickCompSample">
&lt;quickMenuComp :isOpenProps="false" :quickMenuInfo="quickMenuInfo" /&gt;

&lt;script&gt;
import quickMenuComp from "@/components/QuickMenuComp.vue";

export default {

    components: {
        quickMenuComp
    },

    data() {

        return {
            quickMenuInfo : [
                { menuName : '인적사항',        menuRef : 'ref_1' },
                { menuName : '근무조건',        menuRef : 'ref_2' },
                { menuName : '자기소개',        menuRef : 'ref_3' },
            ],
        };
    },
};
&lt;/script&gt;
</div>
								</pre>
							</td>
						</tr>
						<tr><td></td>
							<td class="level2">슬라이드</td>
							<td class="source">
								<div>
									<h3>slot 슬라이딩</h3>
									imgList : {{component.slideSample.imgList}}<br/>
									&lt;slide-comp :list="imgList" v-slot="slide"&gt;&lt;img class="swiper-conts" :src="slide.item"/&gt;&lt;/slide-comp&gt;<br/><br/>
									<slide-comp :list="component.slideSample.imgList" v-slot="slide">{{slide.index}} <img :src="slide.item"/></slide-comp>
								</div>
								<div>
									<h3>이미지 슬라이딩</h3>
									imgList : {{component.slideSample.imgList}}<br/>
									&lt;slide-comp type="image" :list="imgList" /&gt;<br/><br/>
									<slide-comp type="image" :list="component.slideSample.imgList" />
								</div>
								<div>
									<h3>html 슬라이딩</h3>
									htmlList : {{component.slideSample.htmlList}}<br/>
									&lt;slide-comp type="html" :list="htmlList" /&gt;<br/><br/>
									<slide-comp type="html" :list="component.slideSample.htmlList" />
								</div>
								<div>
									<h3>텍스트 슬라이딩</h3>
									textList : {{component.slideSample.textList}}<br/>
									&lt;slide-comp :list="textList" /&gt;<br/><br/>
									<slide-comp :list="component.slideSample.textList" />
								</div>
							</td>
						</tr>
						<tr><td></td>
							<td class="level2">리스트</td>
							<td class="source">
								type : <select v-model="list.type"><option value="ul">ul</option><option value="ol">ol</option></select><br/>
								code : <input type="text" v-model="list.code"/><br/>
								List : <textarea v-model="list.list"/>
								<List :type="list.type" :code="list.code" :list="JSON.parse(list.list)" :clazz="list.clazz" :subKey="list.subKey" :textKey="list.textKey" :clazzKey="list.clazzKey"/>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
</template>

<script>
import slideComp from "@/components/SlideComp.vue";

export default {
	name: 'compGuide',
    components: {
		slideComp, 
    },
	data() {
		return {
			component:{
				pageCompSample : false,// 페이징 소스 샘플보기
				picCompSample : false,// 사진미리보기 소스 샘플보기
				quickCompSample : false,// 바로가기 소스 샘플보기
				slideSample : {
					imgList : ['/images/slide_banr_11.png', '/images/slide_banr_12.png'],
					textList : ["Text 슬라이드 테스트입니다. (1/3)", "가 나 다 라 마 바 사 아 자 차 카 타 파 하 | A B C D E F G H I J K L M N O P Q R S T U V W X Y Z | a b c d e f g h i j k l m n o p q r s t u v w x y z | 1 2 3 4 5 6 7 8 9 0 | (2/3)", "Text 슬라이드 테스트입니다. (3/3)"],
					htmlList : ['<di style="background-color:red">red</div>', '<di style="background-color:green">green</div>', '<di style="background-color:blue">blue</div>'],
				},
			},
			list : {
				type : 'ul',
				code : 'code',
				clazz : ['clazz'],
				subKey : 'list',
				textKey : 'text',
				clazzKey : 'clazz',
				list : JSON.stringify([ {
					text : 'test1',
					code : '01',
					clazz : ['clazz'],
					list : [ {
						text : 'test1-1',
						code : '011',
						clazz : ['clazz']
					} ]
				}, {
					text : 'test2',
					code : '02',
					clazz : ['clazz'],
					list : [ {
						text : 'test2-1',
						code : '021',
						clazz : ['clazz']
					}, {
						text : 'test2-2',
						code : '022',
						clazz : ['clazz']
					} ],
				} ]),
			}
		};
	},
	methods: {
	},
};
</script>