<template>
	<div class="reading_container">
		<div class="reading_info_contents">
			<div class="title">[format] Data Format</div>
			<div class="Board">
				<table class="Board_type1">
					<colgroup><col width="0">
						<col width="100">
						<col width="200">
						<col width="400">
						<col width="*">
					</colgroup>
					<tbody>
						<tr><td></td>
							<td class="level2">구분</td>
							<td class="level2">값(포맷 미적용)</td>
							<td class="level2">샘플</td>
							<td class="level2">소스</td>
						</tr>
						<tr><td></td>
							<td class="level2">공백</td>
							<td class="sample">{{nbsp}}<br/><input type="number" v-model="nbsp"/></td>
							<td class="sample">공백 {{nbsp}}칸 : [<nbsp :n="nbsp"/>]</td>
							<td class="source" v-pre></td>
						</tr>
						<tr><td></td>
							<td class="level2">숫자</td>
							<td class="sample">{{data.number}}<br/><input type="number" v-model="data.number"/></td>
							<td class="sample">{{data.number | number}}<br/><InputComp type="number" v-model="data.number" title="숫자"/></td>
							<td class="source" v-pre>{{data.number | number}}<br/>&lt;InputComp type="number" v-model="data.number" title="숫자"/&gt;</td>
						</tr>
						<tr><td></td>
							<td class="level2">날짜(Date)</td>
							<td class="sample">{{data.dateD}}</td>
							<td class="sample">{{data.dateD | date('yyyy-MM-dd')}}<br/><InputComp type="date" v-model="data.dateD" title="날짜" format="yyyy-MM-dd"/></td>
							<td class="source" v-pre>{{data.dateD | date('yyyy-MM-dd')}}<br/>&lt;InputComp type="date" v-model="data.dateD" title="날짜" format="yyyy-MM-dd"/&gt;</td>
						</tr>
						<tr><td></td>
							<td class="level2">날짜(String)</td>
							<td class="sample">{{data.dateS}}<br/><input type="number" v-model="data.dateS"/></td>
							<td class="sample">{{data.dateS | date('yyyy.MM.dd')}}<br/><InputComp type="date" v-model="data.dateS" title="날짜" format="yyyy.MM.dd"/></td>
							<td class="source" v-pre>{{dateS | date('yyyy.MM.dd')}}<br/>&lt;InputComp type="date" v-model="data.dateS" title="날짜" format="yyyy.MM.dd"/&gt;</td>
						</tr>
						<tr><td></td>
							<td class="level2">날짜(Number)</td>
							<td class="sample">{{data.dateN}}<br/><input type="number" v-model="data.dateN"/></td>
							<td class="sample">{{data.dateN | date('yyyy/MM/dd')}}<br/><InputComp type="date" v-model="data.dateN" title="날짜" format="yyyy/MM/dd"/></td>
							<td class="source" v-pre>{{data.dateN | date('yyyy/MM/dd')}}<br/>&lt;InputComp type="date" v-model="data.dateN" title="날짜" format="yyyy/MM/dd"/&gt;</td>
						</tr>
						<tr><td></td>
							<td class="level2">개월수(months)</td>
							<td class="sample">{{data.months}}<br/><input type="number" v-model="data.months"/></td>
							<td class="sample">{{data.months | months}}<br/><InputComp type="months" v-model="data.months" title="개월수"/></td>
							<td class="source" v-pre>{{data.months | months}}<br/>&lt;InputComp type="months" v-model="data.months" title="개월수"/&gt;</td>
						</tr>
						<tr><td></td>
							<td class="level2">사업자번호</td>
							<td class="sample">{{data.bizNo}}<br/><input type="number" v-model="data.bizNo"/></td>
							<td class="sample">{{data.bizNo | bizNo }}<br/><InputComp type="bizNo" v-model="data.bizNo" title="사업자번호"/></td>
							<td class="source" v-pre>{{ data.bizNo | bizNo }}<br/>&lt;InputComp type="bizNo" v-model="data.bizNo" title="사업자번호"/&gt;</td>
						</tr>
						<tr><td></td>
							<td class="level2">전화번호</td>
							<td class="sample">{{data.tell}}<br/><input type="number" v-model="data.tell"/></td>
							<td class="sample">{{data.tell | tell }}<br/><InputComp type="tell" v-model="data.tell" title="전화번호"/></td>
							<td class="source" v-pre>{{ data.tell | tell }}<br/>&lt;InputComp type="tell" v-model="data.tell" title="전화번호"/&gt;</td>
						</tr>
						<tr><td></td>
							<td class="level2">휴대폰번호</td>
							<td class="sample">{{data.cell}}<br/><input type="number" v-model="data.cell"/></td>
							<td class="sample">{{data.cell | cell }}<br/><InputComp type="cell" v-model="data.cell" title="휴대폰번호"/></td>
							<td class="source" v-pre>{{ data.cell | cell }}<br/>&lt;InputComp type="cell" v-model="data.cell" title="휴대폰번호"/&gt;</td>
						</tr>
						<tr><td></td>
							<td class="level2">전화번호(휴대폰번호 통합)</td>
							<td class="sample">{{data.phone}}<br/><input type="number" v-model="data.phone"/></td>
							<td class="sample">{{data.phone | phone }}<br/><InputComp type="phone" v-model="data.phone" title="전화번호"/></td>
							<td class="source" v-pre>{{ data.phone | phone }}<br/>&lt;InputComp type="phone" v-model="data.phone" title="전화번호"/&gt;</td>
						</tr>
						<tr><td></td>
							<td class="level2">성별</td>
							<td class="sample">{{data.gender}}<br/><input type="text" v-model="data.gender"/></td>
							<td class="sample">{{data.gender | gender }}<br/><InputComp type="gender" v-model="data.gender" title="성별"/></td>
							<td class="source" v-pre>{{ data.gender | gender }}<br/>&lt;InputComp type="gender" v-model="data.gender" title="성별"/&gt;</td>
						</tr>
						<tr><td></td>
							<td class="level2">생년</td>
							<td class="sample">{{data.birthYear}}<br/><input type="number" v-model="data.birthYear"/></td>
							<td class="sample">{{data.birthYear | birthYear }}<br/><InputComp type="birthYear" v-model="data.birthYear" title="생년"/></td>
							<td class="source" v-pre>{{ data.birthYear | birthYear }}<br/>&lt;InputComp type="birthYear" v-model="data.birthYear" title="생년"/&gt;</td>
						</tr>
					</tbody>
				</table>
			</div>
			<div class="title">
				Validation
				<input type="button" value="실행" @click="$.http('/', data).then(res => {response = res.data}).catch(err => $.httpErrorCommon(err, '에러!!!!'))" />
			</div>
			<div class="Board">
				<table class="Board_type1">
					<colgroup><col width="0">
						<col width="100">
						<col width="350">
						<col width="250">
						<col width="*">
					</colgroup>
					<tbody>
						<tr v-for="(rule, key) in rulesMap" :key="key"><td></td>
							<td class="level2">{{key}}</td>
							<td class="sample">
								<b>value : </b>{{data.rulesMap[key] }}<br/>
								<b>args : </b><br/>
								<template v-if="type(rule) == 'object'">
									<input type="text" v-for="(param, idx) in rule" v-model="rule[idx]" :key="key + '_' + idx"/><br/>
								</template>
								<!--input type="number" v-else-if="type(rule) == 'number'" v-model="rulesMap[key]"/-->
								<template v-else-if="type(rule) == 'boolean'">
									<input type="checkbox" v-model="rulesMap[key]"/> {{rule}}
								</template>
								<input type="text" v-else v-model="rulesMap[key]"/>
							</td>
							<td class="sample"><InputComp type="text" v-model="data.rulesMap[key]" :title="key + ' 체크'" :rules="[{rule: key, args : rulesMap[key]}]"/></td>
							<td class="source">&lt;InputComp type="text" v-model="data.rulesMap.{{key}}" title="{{key}} 체크" :rules="[{ rule: {{key}}, args : {{rulesMap[key]}}}]"/&gt;</td>
						</tr>
						<tr><td></td>
							<td class="level2">함수 체크</td>
							<td class="sample">
								<b>value : </b>{{data.rulesMap.func }}<br/>
								<b>function : </b><br/>
								{<div v-for="(func, key) in ruleFunc" :key="key">
									'{{key}}' : {{func.toString()}},
								</div>}
							</td>
							<td class="sample"><InputComp type="text" v-model="data.rulesMap.func" title="함수 체크" :rules="ruleFunc"/></td>
							<td class="source">&lt;InputComp type="text" v-model="data.rulesMap.func" title="함수 체크" :rules="ruleFunc"/&gt;</td>
						</tr>
						<tr><td></td>
							<td class="level2">다중 체크 Array</td>
							<td class="sample">
								<b>value : </b>{{data.rulesMap.array }}<br/>
								<b>rules : </b><br/>
								최소길이 : <input type="text" v-model="rulesMap.minLength"/><br/>
								최대길이 : <input type="text" v-model="rulesMap.maxLength"/><br/>
								필수여부 : <input type="checkbox" v-model="rulesMap.required"/> {{rulesMap.required}}<br/>
								함수 : {{ruleFunc.start1.toString()}}<br/>
							</td>
							<td class="sample"><InputComp type="text" v-model="data.rulesMap.array" title="다중 체크 Array" :rules="[{rule: 'required', args : rulesMap['required']}, {rule: 'minLength', args : rulesMap['minLength']}, {rule: 'maxLength', args : rulesMap['maxLength']}, ruleFunc.start1]"/></td>
							<td class="source">&lt;InputComp type="text" v-model="data.rulesMap.array" title="다중 체크 Array" :rules="[{rule: 'required', args : {{rulesMap['required']}}}, {rule: 'minLength', args : {{rulesMap['minLength']}}}, {rule: 'maxLength', args : {{rulesMap['maxLength']}}}, ruleFunc.start1]"/&gt;</td>
						</tr>
						<tr><td></td>
							<td class="level2">다중 체크 Map</td>
							<td class="sample">
								<b>value : </b>{{data.rulesMap.map }}<br/>
								<b>rules : </b><br/>
								최소길이 : <input type="text" v-model="rulesMap.minLength"/><br/>
								최대길이 : <input type="text" v-model="rulesMap.maxLength"/><br/>
								필수여부 : <input type="checkbox" v-model="rulesMap.required"/> {{rulesMap.required}}<br/>
								함수 : {{ruleFunc.end0.toString()}}<br/>
							</td>
							<td class="sample"><InputComp type="text" v-model="data.rulesMap.map" title="다중 체크 Map" :rules="{'required': rulesMap['required'], 'minLength' : rulesMap['minLength'], 'maxLength' : rulesMap['maxLength'], 'func': ruleFunc.end0 }"/></td>
							<td class="source">&lt;InputComp type="text" v-model="data.rulesMap.map" title="다중 체크 Map" :rules="{ 'required' : {{rulesMap['required']}}, 'minLength' : {{rulesMap['minLength']}}, 'maxLength' : {{rulesMap['maxLength']}}, 'func': ruleFunc.end0}"/&gt;</td>
						</tr>
						<tr><td></td>
							<td class="level2">다중 체크 String</td>
							<td class="sample">
								<b>value : </b>{{data.rulesMap.str }}<br/>
								<b>rules : </b><br/>
								최소길이 : <input type="text" v-model="rulesMap.minLength"/><br/>
								최대길이 : <input type="text" v-model="rulesMap.maxLength"/><br/>
								필수여부 : <input type="checkbox" v-model="rulesMap.required"/> {{rulesMap.required}}<br/>
							</td>
							<td class="sample"><InputComp type="text" v-model="data.rulesMap.str" title="다중 체크 String" :rules="'required:'+ rulesMap['required'] + ',minLength:' + rulesMap['minLength'] + ',maxLength:' + rulesMap['maxLength']"/></td>
							<td class="source">&lt;InputComp type="text" v-model="data.rulesMap.str" title="다중 체크 String" rules="required:{{rulesMap['required']}},minLength:{{rulesMap['minLength']}},maxLength:{{rulesMap['maxLength']}}"/&gt;</td>
						</tr>
						<tr><td></td>
							<td class="level2">유효 입력문자</td>
							<td class="sample">
								<b>value : </b>{{data.inputable }}<br/>
								<b>inputable : </b><input type="text" v-model="inputable"/><br/>
							</td>
							<td class="sample"><InputComp type="text" v-model="data.inputable" title="유효 입력문자" :inputable="inputable"/></td>
							<td class="source">&lt;InputComp type="text" v-model="data.inputable" title="유효 입력문자" :inputable="inputable"/&gt;</td>
						</tr>
						<tr><td></td>
							<td class="level2">유효 입력문자</td>
							<td class="sample">
								<b>value : </b>{{data.inputable2 }}<br/>
								<b>inputable : </b>{{inputable2 }}<br/>
							</td>
							<td class="sample"><InputComp type="text" v-model="data.inputable2" title="유효 입력문자" :inputable="inputable2"/></td>
							<td class="source">&lt;InputComp type="text" v-model="data.inputable2" title="유효 입력문자" :inputable="inputable2"/&gt;</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'formatGuide',
	data() {
		return {
			nbsp: 1,
			data: {
				number : 10000,
				dateS : new Date().format('yyyyMMdd'),
				dateD : new Date(),
				dateN : new Date().getTime(),
				months: 28,
				bizNo : '1234567891',
				tell  : '01011112221',
				cell  : '01011112222',
				phone : '01011112223',
				gender	: 'M',
				birthYear	: '1978',
				rulesMap : {},
				inputable : '',
				inputable2 : '',
			},
			
			inputable : '',
			inputable2 : ['num', 'uppr'],
			rulesMap : {
				maxLength : 5,
				minLength : 5,
				length : 5,
				required : true,
			},
			ruleFunc : {
				'start1' : v => v.indexOf('1') == 0 || '입력 1로 시작해주세요',
				'end0'   : v => v.lastIndexOf('0') == v.length - 1 || '입력 0으로 종료해주세요',
			},
		};
	},
	methods:{
		type(val){
			return typeof val;
		}
	},
};
</script>
<style>
	input { max-width: calc(100% - 40px) !important; }
</style>