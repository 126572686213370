<template>
	<div class="reading_container" ref="router">
		<div class="reading_info_contents">
			<div class="title">[router] 페이지 이동</div>
			<div class="Board">
				<table class="Board_type1">
					<colgroup><col width="0">
						<col width="200">
						<col width="350">
						<col width="*">
					</colgroup>
					<tbody>
						<tr><td></td>
							<td class="level2">url 이동</td>
							<td class="source">$router.push(url)<br/>$router.push({ path : url})</td>
							<td class="sample">
								현재페이지 : {{$route.fullPath}} <a href="javascript:void(0)" @click="goRef($refs.route)">페이지정보 상세</a><br/>
								url : <input type="text" v-model="url"/><br/>
								<a href="javascript:void(0)" @click="$router.push(url)">URL 이동</a><br/>
								<a href="javascript:void(0)" @click="$router.push({ path : url })">객체를 통한 이동</a>
							</td>
						</tr>
						<tr><td></td>
							<td class="level2">name 이동</td>
							<td class="source">$router.push({ name : pageName, params : param})</td>
							<td class="sample">
								현재페이지 : {{$route.fullPath}} <a href="javascript:void(0)" @click="goRef($refs.route)">페이지정보 상세</a><br/>
								pageID : <input type="text" v-model="pageName"/><br/>
								param : <textarea type="text" v-model="param" cols="50" rows="5"></textarea><br/>
								<label><input type="checkbox" @change="checkRouteSave"/> 현재파라미터 기억</label> : 현재 페이지의 파라미터를 저장 후 파라미터 이동 시 파라미터에 caller를 세팅한다.<br/>
								<a href="javascript:void(0)" @click="$router.push({ name : pageName, params : JSON.parse(param) })">이동</a>
							</td>
						</tr>
						<tr><td></td>
							<td class="level2">히스토리 추가 없이 이동<br/>(현재페이지 대체)</td>
							<td class="source">$router.replace({ name : pageName, params : param})</td>
							<td class="sample">
								현재페이지 : {{$route.fullPath}} <a href="javascript:void(0)" @click="goRef($refs.route)">페이지정보 상세</a><br/>
								pageID : <input type="text" v-model="pageName"/><br/>
								param : <textarea type="text" v-model="param" cols="50" rows="5"></textarea><br/>
								<a href="javascript:void(0)" @click="$router.replace({ name : pageName, params : JSON.parse(param) })">이동</a>
							</td>
						</tr>
						<tr><td></td>
							<td class="level2">히스토리 이동</td>
							<td class="source">$router.go( num )</td>
							<td class="sample">
								<a href="javascript:void(0)" @click="$router.go(1)">앞으로 이동 (num = 1)</a><br/>
								<a href="javascript:void(0)" @click="$router.go(-1)">뒤로 이동 (num = -1)</a>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'routerGuide',
	data() {
		return {
			url : '/guide/GUD001M02?test=1',
			pageName : 'GUD001M02',
			param : '{ "param" : "test" }',
		};
	},
	methods: {

		checkRouteSave(event){
			//console.log('checkRouteSave', event.target.checked);
			var json = JSON.parse(this.router.param);
			if(event.target.checked){
				json.caller = json.caller || {};
				json.caller.name = this.$route.name;
				var params = JSON.parse(JSON.stringify(this.$route.params));
				delete params.caller;
				params.date = new Date().format('yyyyMMdd');
				json.caller.params = params;
			} else {
				delete json.caller;
			}
			this.router.param = JSON.stringify(json);
		},
	},
};
</script>