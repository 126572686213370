<template>
    <div class="reading_container">
		<div class="reading_info_contents">
			<div class="title">[popup] 팝업</div>
			<div class="Board">
				<table class="Board_type1">
					<colgroup><col width="0">
						<col width="200">
						<col width="350">
						<col width="*">
					</colgroup>
					<tbody>
						<tr><td></td>
							<td class="level2">팝업</td>
							<td class="source">$.popup(path, params, opt)<br/>.then(res => { /* do success */})<br/>.catch(err => { /* do error */ });</td>
							<td class="sample">
								path : <input type="text" v-model="path"/><br/>
								width : <input type="number" v-model="width"/><br/>
								height : <input type="number" v-model="height"/><br/>
								param : <textarea type="text" v-model="param"></textarea><br/>
								<a href="javascript:void(0)" @click="$.popup(path, JSON.parse(param), { width : width, height : height}).then(res => {response = res;})">팝업열기</a> | 
								<a href="javascript:void(0)" @click="response = ''">결과삭제</a><br/>
								결과 : {{ response }}
							</td>
						</tr>
						<tr><td></td>
							<td class="level2">로딩바</td>
							<td class="source">$.loading(on)</td>
							<td class="sample"><a href="javascript:void(0)" @click="loading">로딩바 열기</a></td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
</template>

<script>

export default {
	name: 'popupGuide',
	data() {
		return {
			path : '/guide/GUD001P01',
			width : 500,
			height : 600,
			param : '{}',
			response : ''
		};
	},
	methods: {

		loading(){
			this.$.loading(true);
			setTimeout(()=>{this.$.loading(false)}, 3000)
		},
	},
};
</script>